
#contFooter {
  background-color: #f8f9fa; 
  position: fixed; 
  bottom: 0; 
  left: 0; 
  width: 100%; 

}


.banner {
  height: 80px;
  z-index: 2;
}


#contFooter .col-12 .d-flex.justify-content-center {
  font-size: 16px;
  color: #6c757d; 
}


#contFooter .col-12.text-center {
  font-size: 14px;
  color: #6c757d;
  margin-top: 10px;
}


.footer {
  margin-top: 40px;
  margin-bottom: 10px;
}

.brand-logo {
  height: 80px;

  z-index: 2;
}

